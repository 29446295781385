<template>
  <div class="userNewFileVideoPoster">
    <div class="videoPosterTitle">
      <span class="title">{{ $locale["video_poster_title"] }}</span>
      <a class="videoCaptureBtn" @click="captureThumbnail">
        <span>{{ $locale["capture"] }}</span>
        <iconic name="image" />
      </a>
    </div>
    <div class="imageCover" :style="`--image:url('${src}')`" @click="src && mediaView({ src, type: 'image/png' })">
      <iconic v-if="loading" dcolor name="gspinner" />
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      src: "",
      loading: false,
    };
  },
  methods: {
    captureThumbnail: function(options = {}) {
      this.loading = true;
      const video = document.querySelector(".newFilePreviewVideo");
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
      this.src = canvas.toDataURL("image/png");
      this.$emit("poster", this.src);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.userNewFileVideoPoster {
  .imageCover {
    width: 72px;
    height: 72px;
    background-color: $alto;
    background-image: var(--image);
    background-position: center;
    background-size: cover;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200%;
    @include dshadow;
  }
  .videoPosterTitle {
    padding: $mpadding 0;
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
    }
  }
  .videoCaptureBtn {
    @include PrimaryButton();
    border-radius: 2pc;
    padding: $mpadding/4 $mpadding/2;
    box-shadow: none;
    display: flex;
    gap: $mpadding/2;
    align-items: center;
  }
  .imageCover img {
    opacity: 0;
  }
}
</style>
